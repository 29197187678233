import { EventSourceMessage } from '@microsoft/fetch-event-source';
import { createAppAsyncThunk } from './create-typed-async-thunk';
import folderService from '../../services/folder.service';
import userService from '../../services/user.service';
import {
    selectAllSubFolders,
    setRootFolders,
    setUpdatedFolderBroadcast,
    updateSubFoldersList,
} from '../slices/app/folder.slice';
import { updatePlanning } from './planning-slice.thunk';
import Folder from '../models/folder.model';
import { selectPlanningSelected } from '../slices/app/planning.slice';
/**
 * Redux thunk for handling folder updates from broadcast events
 * 
 * This module contains thunks for managing folder state updates received via broadcast events.
 * It handles folder creation, updates, and deletions by:
 * 
 * 1. Updating the root folders list
 * 2. Fetching updated folder details 
 * 3. Managing subfolder relationships
 * 4. Broadcasting folder changes to components
 * 
 * The main thunk is handleFolderUpdate which:
 * - Receives broadcast events about folder changes
 * - Updates the root folder list
 * - Fetches the updated folder details
 * - Updates parent-child folder relationships
 * - Broadcasts changes via setUpdatedFolderBroadcast
 * - Updates subfolder lists in the store
 * 
 * @module folder-slice.thunk
 */

export const handleFolderUpdate = createAppAsyncThunk(
    'folder/handleFolderUpdate',
    async (event: EventSourceMessage, { dispatch, getState }) => {
        try {
            if (event.data === '') return;
            const data = JSON.parse(event.data);
            console.log('data', data);
            if (data.type === 'Folder') {
                // Fetch and update the root folder list
                const folderList = await folderService.listFolders();
                dispatch(setRootFolders(folderList));

                // Attempt to fetch the updated folder details
                // If folder was deleted, updatedFolder will be null
                let updatedFolder;
                try {
                    updatedFolder = await folderService.showFolder(data.id);
                } catch (e) {
                    updatedFolder = null;
                }

                // Broadcast the folder update event with the new data
                dispatch(setUpdatedFolderBroadcast({ ...data, updatedData: updatedFolder }));

                // Get current subfolders list from state and create a mutable copy
                const subFoldersList = selectAllSubFolders(getState());
                const cloneSubfolders: Map<string, Folder> = new Map(Object.entries(subFoldersList));

                // Handle folder update/creation case
                if (updatedFolder && updatedFolder.parentId && cloneSubfolders.has(updatedFolder.parentId)) {
                    const parentFolder = cloneSubfolders.get(updatedFolder.parentId);
                    // Check if folder already exists in parent's children
                    const existingFolderIndex = parentFolder.childFolders.findIndex((f) => f.id === updatedFolder.id);

                    if (existingFolderIndex !== -1) {
                        // Update existing folder in parent's children
                        parentFolder.childFolders[existingFolderIndex] = updatedFolder;
                    } else {
                        // Add new folder to parent's children
                        parentFolder.childFolders.push(updatedFolder);
                    }

                    // Update the store with modified subfolder list
                    dispatch(
                        updateSubFoldersList({
                            folderId: updatedFolder?.parentId || '',
                            data: parentFolder.childFolders,
                        })
                    );
                } 
                // Handle folder deletion case
                else if (updatedFolder === null) {
                    // Search through all folders to find and remove the deleted folder
                    /* eslint-disable-next-line */
                    for (const [, folder] of cloneSubfolders) {
                        const childIndex = folder.childFolders?.findIndex((f) => f.id === +data.id);
                        if (childIndex !== -1) {
                            // Remove the deleted folder from parent's children
                            folder.childFolders.splice(childIndex, 1);
                            // Update the store with modified subfolder list
                            dispatch(
                                updateSubFoldersList({
                                    folderId: folder.id.toString() || '',
                                    data: folder.childFolders,
                                })
                            );
                            break;
                        }
                    }
                }
            }
            if (data.type === 'Planning') {
                let updatedPlanning;
                const planningSelected = selectPlanningSelected(getState());
                try {
                    updatedPlanning = await folderService.showPlanning(data.id);
                    const isFavorite = await userService.isFavoritePlanning(data.id);
                    updatedPlanning.favorite = isFavorite;
                    if (+planningSelected?.id === +data.id) {
                        // update planningSelected state
                        dispatch(updatePlanning({planning: updatedPlanning}));
                    }
                    dispatch(setUpdatedFolderBroadcast({ ...data, updatedData: updatedPlanning }));
                } catch (e) {
                    updatedPlanning = null;
                    dispatch(setUpdatedFolderBroadcast({ ...data, updatedData: updatedPlanning }));
                }
            }
        } catch (err) {
            console.error('Error handling folder update:', err);
            throw err;
        }
    }
);

export default handleFolderUpdate;
