import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Empty, Row, Spin, Progress, Menu, Typography, Modal, Layout, Badge, Button } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
    PlusOutlined,
    MenuOutlined,
    CaretLeftFilled,
    CaretRightFilled,
    ShareAltOutlined,
    WarningFilled,
    EyeOutlined,
} from '@ant-design/icons';
import { clone } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import SplitPane from 'split-pane-react/esm/SplitPane';
import { Pane } from 'split-pane-react';
import LeftSidebar from '../components/layouts/app/LeftSidebar';
import TopMenu from '../components/layouts/app/TopMenu';
import Gantt from '../components/plannings/Gantt';
import { PrintTemplatesMenuContextProvider } from '../contexts/app/PrintTemplatesMenuContext';
// import SchedulerComponent from '../components/scheduler/Scheduler';
// import ResourcesCalendar from '../components/scheduler/ResourcesCalendar';
import AddNewViewModal from '../components/views/AddNewViewModal';
import { notificationError, requestError } from '../helpers/notification';
import SessionInfo from '../components/extra/SessionInfo';
import { TAB_TYPE } from '../constants/Tabs';
import authService from '../services/auth.service';
import SharedViewModal from '../components/views/SharedViewModal';
// import { NeedChartContextProvider } from '../contexts/chart/ChartContext';
import GanttViewChartContainer from '../components/charts/GanttViewChartContainer';
import { RESOURCE_VIEW_MODE } from '../constants/Generic';
import HistogramTabTopMenu from '../components/histogram-tab/HistogramTabTopMenu';
import HistogramTabChartContainer from '../components/histogram-tab/HistogramTabChartContainer';
import ViewTabs from '../components/views/ViewTabs';
import { saveScrollState } from '../components/plannings/gantt_events';
import SSEErrorModal from '../components/extra/SSEErrorModal';
import {
    getActiveTabData,
    getPlanningTabs,
    refreshPlanningTabs,
    selectActiveTab,
    selectPlanningTabs,
    selectSharedTabs,
    selectTabPreferences,
    setActiveTab,
} from '../redux/slices/app/tab.slice';
import {
    selectLoadingGantt,
    selectPlanningSelected,
    selectProcessingGantt,
    selectSpinProgress,
    setLoadingGantt,
} from '../redux/slices/app/planning.slice';
import { selectSelectedFolder } from '../redux/slices/app/folder.slice';
import { selectCurrentUser, selectUserPreferences } from '../redux/slices/app/user.slice';
import { refreshPlanningSelected } from '../redux/thunks/planning-slice.thunk';
import { HistogramChartContextProvider } from '../contexts/chart/HistogramChartContext';
import useMailTo from '../hooks/useMailTo.hook';

const { Text } = Typography;

const layoutCSS = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const ActivitiesWrapperStyle = createGlobalStyle`
    .activities-wrapper {
        height: calc(100vh - 40px);
    }
    .activities-wrapper.single-tab {
        width: 98vw !important;
    }
`;

const MenuCollapseToogle = styled.button`
    height: 44px;
    width: 20px;
    position: absolute;
    top: 0px;
    left: -20px;
    border-radius: 4px;
`;

const BottomInfo = styled.div`
    position: absolute;
    bottom: 0px;
    margin-left: 20px;
    left: 0px;
`;
// const AdminInfo = styled.div`
//     width: 217px;
//     background: #ff4d4f;
//     margin-right: 20px;
//     border-radius: 2px;
// `;
const TopInfo = styled.div`
    width: 217px;
    position: absolute;
    top: -20px;
    left: 0px;
    background: #ff4d4f;
    border-radius: 2px;
    line-height: 20px;
`;

const SIDER_DEFAULT_WIDTH = 217;
const MAX_TIME_LOAD = 30000; // 30 seconds
let panelSizes = [SIDER_DEFAULT_WIDTH, 'auto'];

const Planning = (componentProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('translation', { keyPrefix: 'planning_tabs' });
    const sseTranslation = useTranslation('translation', { keyPrefix: 'sse_test' }).t;
    const { i18n } = useTranslation();
    const sharedTabs = useSelector(selectSharedTabs);
    const tabPreferences = useSelector(selectTabPreferences);
    const activeTab = useSelector(selectActiveTab);
    const planningTabs = useSelector(selectPlanningTabs);
    const loadingGantt = useSelector(selectLoadingGantt);
    const spinProgress = useSelector(selectSpinProgress);
    const processingGantt = useSelector(selectProcessingGantt);
    const folderSelected = useSelector(selectSelectedFolder);
    const planningSelected = useSelector(selectPlanningSelected);
    const currentUser = useSelector(selectCurrentUser);
    const userPreferences = useSelector(selectUserPreferences);
    const [collapsed, setCollapsed] = useState(false);
    const [addingNewView, setAddingNewView] = useState(false);
    const [layoutSplitPanelSizes, setLayoutSplitPanelSizes] = useState([SIDER_DEFAULT_WIDTH, 'auto']);
    const [ganttSplitPanelSizes, setGanttSplitPanelSizes] = useState(['auto', 'auto']);
    const programName = JSON.parse(localStorage.getItem('prg'))?.name;

    const indicator = <Progress percent={spinProgress} steps={5} showInfo={false} strokeColor="#fbbe3f" />;
    const [currentViews, setCurrentViews] = useState([]);
    const [shownViewIds, setShownViewIds] = useState([]);
    const [planningViews, setPlanningViews] = useState([]);
    const [isSingleTab, setIsSingleTab] = useState(false);

    // const [initialTimeLoad, setInitialTimeLoad] = useState(Date.now());
    const [isLoadTimeExceeded, setIsLoadTimeExcedeed] = useState(false);
    const [showTimeExcedeedModal, setShowTimeExcedeedModal] = useState(false);

    const initialTimeLoad = useRef();
    const isLoadTimeExceededRef = useRef();

    const AddViewModalRef = useRef();
    const SharedViewModalRef = useRef();

    const SSEErrorModalRef = useRef();
    const [SSEStatusError, setSSEStatusError] = useState();

    const { sendLatencyProblemMail } = useMailTo();


    useEffect(() => {
        console.log('changing tabs', planningTabs);
        setPlanningViews(clone(planningTabs));
        setShownViewIds(clone(planningTabs.filter((tab) => tab.display).map((tab) => tab.id)));
    }, [planningTabs]);

    useEffect(() => {
        isLoadTimeExceededRef.current = isLoadTimeExceeded;
    }, [isLoadTimeExceeded]);

    const checkPlanningLoad = () => {
        if (
            initialTimeLoad.current &&
            Date.now() - initialTimeLoad.current > MAX_TIME_LOAD &&
            isLoadTimeExceededRef.current === false
        ) {
            console.log('SHOW MODAL');
            setIsLoadTimeExcedeed(true);
            setShowTimeExcedeedModal(true);
        }
    };

    useEffect(() => {
        const timer = setInterval(checkPlanningLoad, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (loadingGantt) {
            initialTimeLoad.current = Date.now();
            setIsLoadTimeExcedeed(false);
        } else {
            setShowTimeExcedeedModal(false);
            initialTimeLoad.current = undefined;
        }
    }, [loadingGantt]);

    useEffect(() => {
        if (planningViews.length > 0 && shownViewIds) {
            const currentViewList = [];
            shownViewIds.forEach((id) => {
                const viewObj = planningViews.find((i) => i.id === id);
                if (viewObj) {
                    currentViewList.push(viewObj);
                }
            });
            if (shownViewIds.length === 1) {
                dispatch(setActiveTab(currentViewList[0]));
                dispatch(getActiveTabData(planningSelected.id));
            }
            // eslint-disable-next-line
            if (componentProps?.match?.params.viewId) {
                setIsSingleTab(true);
                setLayoutSplitPanelSizes([0, 'auto']);
                // eslint-disable-next-line
                const selectedView = currentViewList.find((i) => i.id === Number(componentProps?.match?.params.viewId));
                if (selectedView) {
                    dispatch(setActiveTab(selectedView));
                    dispatch(getActiveTabData(planningSelected.id));
                    setCurrentViews([selectedView]);
                } else {
                    notificationError('Séléction de la vue', "La vue souhaitée n'existe pas");
                }
            } else {
                setCurrentViews(currentViewList);
            }
            // if (currentViewList.length === 1) {
            //     setActiveView(currentViewList[0]);
            // }
        } else {
            // no views
            setCurrentViews([]);
            // setActiveView(null);
        }
    }, [planningViews, shownViewIds]);

    const handleNewTabOrder = (newOrder) => {
        const tabUpdatePromises = newOrder.map((id, index) => authService.updatePlanningTab(id, { order: index }));
        Promise.all(tabUpdatePromises).then(() => dispatch(getPlanningTabs()));
        setShownViewIds(newOrder.map((i) => Number(i)));
    };

    const handleAddView = async (view) => {
        const updatedTab = await authService.updatePlanningTab(view.id, { display: true });
        dispatch(refreshPlanningTabs('edit', updatedTab));
        // setShownViewIds((old) => [...old, view.id]);
    };

    const handleAddNewView = async (viewObject) => {
        try {
            setAddingNewView(true);
            await authService.createPlanningTab(planningSelected.id, viewObject.type, viewObject.name);
            // refreshPlanningTabs('new', newTab);
            dispatch(getPlanningTabs());
            setAddingNewView(false);
        } catch (error) {
            requestError(error, t('new_view'));
        }
    };

    const viewsChoiceRender = (
        <Menu>
            {planningViews
                .filter((i) => shownViewIds.indexOf(i.id) === -1)
                .map((view) => (
                    <Menu.Item key={`viewChoice${view.id}`} onClick={() => handleAddView(view)} icon={<EyeOutlined />}>
                        {view.name}
                    </Menu.Item>
                ))}
            <Menu.Item key="viewChoice-new" icon={<PlusOutlined />} onClick={() => AddViewModalRef.current.openModal()}>
                <Text italic strong>
                    {t('add_new_view')}
                </Text>
            </Menu.Item>
            {sharedTabs.length > 0 && (
                <Menu.Item
                    key="viewChoice-shared"
                    icon={<ShareAltOutlined />}
                    onClick={() => SharedViewModalRef.current.openModal()}
                >
                    <Badge dot>
                        <Text strong={sharedTabs.length > 0}>{t('shared_views')}</Text>
                    </Badge>
                </Menu.Item>
            )}
        </Menu>
    );

    const viewRender = () => {
        const view = activeTab;
        let render = '';

        switch (view.tabType) {
            case TAB_TYPE.GANTT: {
                const showHistogram =
                    tabPreferences?.gantt_parameters?.showResources === RESOURCE_VIEW_MODE(i18n).NEEDS.value;
                render = (
                    <div className="flex-grow flex flex-col" style={{ height: '100px' }}>
                        <SplitPane
                            split="horizontal"
                            sizes={showHistogram ? ganttSplitPanelSizes : ['100%', 0]}
                            allowResize={showHistogram}
                            onChange={(sizes) => (showHistogram ? setGanttSplitPanelSizes(sizes) : undefined)}
                        >
                            <Pane>
                                <div style={layoutCSS}>
                                    <div className="h-full w-full flex-grow flex flex-col">
                                        <TopMenu />
                                        <Gantt />
                                    </div>
                                </div>
                            </Pane>
                            <Pane>
                                {/* <SliceContextProvider> */}
                                {showHistogram && (
                                    <div style={layoutCSS}>
                                        <HistogramChartContextProvider>
                                            <GanttViewChartContainer />
                                        </HistogramChartContextProvider>
                                    </div>
                                )}
                                {/* </SliceContextProvider> */}
                            </Pane>
                        </SplitPane>
                    </div>
                );
                break;
            }
            case TAB_TYPE.RESOURCE:
                render = (
                    // <SliceContextProvider>
                    <div className="flex-grow flex flex-col" style={{ height: '100px' }}>
                        <HistogramChartContextProvider>
                            <HistogramTabTopMenu />
                            <HistogramTabChartContainer />
                        </HistogramChartContextProvider>
                    </div>
                    // </SliceContextProvider>
                );
                break;
            // case TAB_TYPE.NEEDS_HISTOGRAM:
            //     render = (
            //         <div>
            //             Besoins du planning
            //         </div>
            //     );
            //     break;
            // case TAB_TYPE.AGENDA:
            //     render = (
            //         <div className="flex flex-grow flex-col">
            //             <SchedulerComponent />
            //         </div>
            //     );
            //     break;
            // case TAB_TYPE.CALENDAR:
            //     render = (
            //         <div>
            //             <ResourcesCalendar />
            //         </div>
            //     );
            //     break;

            default:
                render = <Empty description="Pas de vue correspondante" />;
                break;
        }
        return render;
    };

    const handleChangeView = async (activeKey) => {
        dispatch(setLoadingGantt(true));
        await saveScrollState();
        const newActiveView = currentViews.find((i) => i.id === Number(activeKey));
        console.log('changing', newActiveView);
        await dispatch(setActiveTab(newActiveView));
        await dispatch(getActiveTabData(planningSelected.id));
        dispatch(refreshPlanningSelected());
    };

    const resizeCallback = (sizes) => {
        panelSizes = sizes;
        setLayoutSplitPanelSizes(sizes);
        // const siderContainerUpdated = document.getElementById('sider-container');
        // setSiderWidth(siderContainerUpdated.clientWidth)
    };

    const handleCollapseSider = () =>
        setCollapsed((old) => {
            const isCollapsed = !old === true;
            if (isCollapsed) {
                setLayoutSplitPanelSizes([0, 'auto']);
            } else {
                setLayoutSplitPanelSizes(panelSizes);
                // setTimeout(() => {
                //     resizeCallback();
                // }, 500);
                // setSplitPaneKey(generateString(8));
            }
            return !old;
        });

    return (
        <DndProvider backend={HTML5Backend}>
            <PrintTemplatesMenuContextProvider>
                <ActivitiesWrapperStyle />
                <Layout className="page-wrapper bg-white h-screen">
                    <SplitPane sizes={layoutSplitPanelSizes} onChange={(sizes) => resizeCallback(sizes)}>
                        <Pane minSize={SIDER_DEFAULT_WIDTH}>
                            <div style={{ layoutCSS }} className="relative ">
                                {currentUser.isAdmin && !currentUser.readOnly && (
                                    <TopInfo className="text-center text-white">{t('admin_mode')}</TopInfo>
                                )}
                                {currentUser.readOnly && (
                                    <TopInfo className="text-center text-white">{t('read_only_mode')}{planningSelected}</TopInfo>
                                )}
                                {!isSingleTab && (
                                    <>
                                        <MenuCollapseToogle
                                            className="bg-transparent hover:bg-primary"
                                            title={`${collapsed ? t('show_menu') : t('hide_menu')}`}
                                            onClick={() => handleCollapseSider()}
                                        >
                                            {collapsed ? <CaretRightFilled /> : <CaretLeftFilled />}
                                            <MenuOutlined />
                                        </MenuCollapseToogle>
                                        <div className="bg-transparent w-full page-sider">
                                            <LeftSidebar />
                                        </div>
                                    </>
                                )}
                            </div>
                        </Pane>
                        <div style={{ layoutCSS }}>
                            <Layout>
                                <Row className="flex flex-grow flex-nowrap">
                                    <Spin
                                        spinning={!userPreferences || loadingGantt || processingGantt}
                                        size="large"
                                        wrapperClassName="ant-spin-full w-full"
                                        tip={indicator}
                                    >
                                        <Col
                                            className={`flex-grow flex flex-col overflow-x-visible activities-wrapper ${
                                                planningSelected === null ? 'justify-center' : ''
                                            }`}
                                            style={{ paddingLeft: `${collapsed || isSingleTab ? 0 : 20}px` }}
                                        >
                                            {planningSelected && (
                                                <>
                                                    <ViewTabs
                                                        currentViews={currentViews}
                                                        activeTab={activeTab}
                                                        handleNewTabOrder={handleNewTabOrder}
                                                        handleChangeView={handleChangeView}
                                                        viewsChoiceRender={viewsChoiceRender}
                                                        addingNewView={addingNewView}
                                                        sharedTabs={sharedTabs}
                                                        isSingleTab={isSingleTab}
                                                    />
                                                    {!loadingGantt && activeTab && tabPreferences && viewRender()}
                                                </>
                                            )}
                                            {planningSelected && !activeTab && !loadingGantt && (
                                                <div className="flex-grow ">
                                                    <div className="h-full w-full flex justify-center items-center">
                                                        <Empty description={t('no_active_view')} />
                                                    </div>
                                                </div>
                                            )}
                                            {!planningSelected && !loadingGantt && (
                                                <div className="flex justify-center">
                                                    <Empty description={t('no_planning_selected')} />
                                                    {!userPreferences || loadingGantt || processingGantt}
                                                </div>
                                            )}
                                        </Col>
                                    </Spin>
                                </Row>
                            </Layout>
                        </div>
                    </SplitPane>
                    <BottomInfo className="ml-2 flex">
                        {SSEStatusError && (
                            <label className="mr-2">
                                <Button
                                    type="danger"
                                    className="flex items-center"
                                    size="small"
                                    onClick={() => SSEErrorModalRef.current.openModal()}
                                >
                                    <WarningFilled className="mr-1 animate-ping" />
                                    {sseTranslation('connexion_problem')}
                                    <WarningFilled className="ml-1 animate-ping" />
                                </Button>
                            </label>
                        )}
                        <div>
                            <SessionInfo />
                            {(collapsed || isSingleTab) && (
                                <>
                                    <label>{t('program')}: </label>
                                    <span>{programName}</span> &nbsp; &nbsp;
                                    <label>{t('folder')}: </label>
                                    <span>{folderSelected?.name}</span> &nbsp; &nbsp;
                                    <label>{t('planning')}: </label>
                                    <span>{planningSelected?.name}</span>
                                </>
                            )}
                        </div>
                    </BottomInfo>
                </Layout>
                <Modal
                    open={isLoadTimeExceeded && showTimeExcedeedModal}
                    onCancel={() => setShowTimeExcedeedModal(false)}
                    footer={null}
                >
                    <Row>{t('planning_loading_warning')}</Row>
                    <Row className="mt-2 flex items-center justify-center">
                        <Button
                            type="primary"
                            className="mr-2"
                            onClick={() => {
                                document.getElementById('explorer-modal')?.click();
                                setShowTimeExcedeedModal(false);
                            }}
                        >
                            {t('planning_loading_explorer')}
                        </Button>
                        <Button className="mr-2" type="primary" onClick={() => sendLatencyProblemMail()}>
                            {t('contact_support_team')}
                        </Button>

                        <Button onClick={() => window.location.reload()}> {t('planning_loading_reload')}</Button>
                    </Row>
                </Modal>

                <SSEErrorModal ref={SSEErrorModalRef} onError={(error) => setSSEStatusError(error)} />

                <AddNewViewModal onOk={handleAddNewView} ref={AddViewModalRef} />
                <SharedViewModal ref={SharedViewModalRef} />
            </PrintTemplatesMenuContextProvider>
        </DndProvider>
    );
};

export default Planning;
