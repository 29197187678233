import { Middleware } from '@reduxjs/toolkit';
import { EventSourceMessage, fetchEventSource } from '@microsoft/fetch-event-source';
import handleActivityBroadcastMessage, { handleGeneralBroadcastMessage } from '../thunks/broadcast-slice.thunk';
import { setConnectionStatus } from '../slices/app/broadcast.slice';
import { AppDispatch } from '../store';

interface SSEConfig {
  controller: AbortController;
  type: 'activity' | 'general';
}

export const broadcastMiddleware: Middleware = (store) => {
    const connections = new Map<string, SSEConfig>();

    const setupSSEConnection = (
        url: string,
        type: 'activity' | 'general',
        messageHandler: Function
    ) => {
        // Abort existing connection if any
        if (connections.has(type)) {
            connections.get(type)?.controller.abort();
        }

        const controller = new AbortController();
        connections.set(type, { controller, type });

        const accessToken = localStorage.getItem('accessToken');
        
        fetchEventSource(url, {
            signal: controller.signal,
            openWhenHidden: true,
            headers: {
                Accept: 'text/event-stream',
                'Cache-Control': 'no-cache',
                Connection: 'keep-alive',
                Authorization: `Bearer ${accessToken}`,
            },
            onopen: async (response) => {
                if (response.ok) {
                    store.dispatch(setConnectionStatus(true));
                    return;
                }
                throw new Error(`Failed to connect: ${response.status} ${response.statusText}`);
            },
            onmessage: event => {
                (store.dispatch as AppDispatch)(messageHandler(event));
            },
            onerror(error) {
                console.error(`${type} connection error:`, error);
                store.dispatch(setConnectionStatus(false));
                // return;
            },
            onclose() {
                store.dispatch(setConnectionStatus(false));
                setTimeout(() => {
                    if (!connections.has(type)) return; // Don't reconnect if deliberately disconnected
                    setupSSEConnection(url, type, messageHandler);
                }, 5000);
            }
        }).catch(error => {
            if (error.name === 'AbortError') {
                console.log(`${type} connection aborted`);
            } else {
                console.error(`${type} connection error:`, error);
            }
        });
    };

    return next => (action: any) => {
        switch (action.type) {
            case 'broadcast/connect-pusher':
                setupSSEConnection(
                    action.payload.url,
                    'activity',
                    (event: EventSourceMessage) => handleActivityBroadcastMessage(event)
                );
                break;

            case 'broadcast/connect-general':
                setupSSEConnection(
                    action.payload.url,
                    'general',
                    (event: EventSourceMessage) => handleGeneralBroadcastMessage(event)
                );
                break;

            case 'broadcast/disconnect-pusher':
                if (connections.has('activity')) {
                    connections.get('activity')?.controller.abort();
                    connections.delete('activity');
                }
                break;

            case 'broadcast/disconnect-general':
                if (connections.has('general')) {
                    connections.get('general')?.controller.abort();
                    connections.delete('general');
                }
                break;
                
            default:
                break;
        }

        return next(action);
    };
};

export default broadcastMiddleware;