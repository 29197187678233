import { EventSourceMessage } from '@microsoft/fetch-event-source';
import { createAppAsyncThunk } from './create-typed-async-thunk';
import AuthService from '../../services/auth.service';
import TeamService from '../../services/team.service';
import { selectCurrentUser, getUserPreferences, setCurrentUser, selectTeamList, setTeamList } from '../slices/app/user.slice';
import { setUserBroadcastData } from '../slices/app/user-broadcast.slice';

export const handleUserBroadcastMessage = createAppAsyncThunk(
  'broadcast/handleUserMessage',
  async (event: EventSourceMessage, { getState, dispatch }) => {
    try {
      const data = JSON.parse(event.data);
      console.log('___________________data user',data);
      const currentUser = selectCurrentUser(getState());
      const teamList = selectTeamList(getState());
      
      if (data.type === 'User') {
        dispatch(setUserBroadcastData({ 
          updatingData: true,
          updatedUserId: data.id
        }));

        if (data.id === currentUser.userId) {
          await AuthService.getSession();
          const updatedUser = AuthService.getCurrentUser();
          dispatch(setCurrentUser({ ...updatedUser, jobName: updatedUser?.job?.name }));
        }
        
        dispatch(setUserBroadcastData({ updatingData: false }));
      }

      if (data.type === 'Team') {
        const teams = await TeamService.listTeams(false);
        const updatedTeamObject = teams.find((i) => i.id === +data.id);
        
        dispatch(setUserBroadcastData({ 
          updatedTeam: updatedTeamObject || { id: data.id }
        }));

        const indexTeam = teamList.findIndex((i) => i.id === +data.id);
        if (indexTeam !== -1 && updatedTeamObject) {
          const cloneTeam = [...teamList];
          cloneTeam[indexTeam] = updatedTeamObject;
          dispatch(setTeamList(cloneTeam));
        } else {
          dispatch(setTeamList(teams));
        }
      }

      if (data.type === 'Preference' && data.user !== currentUser.userId && data.id === currentUser.userId) {
        await dispatch(getUserPreferences());
      }

    } catch (error) {
      console.error('Error handling user broadcast message:', error);
    }
  }
); 

export default handleUserBroadcastMessage;