import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

/**
 * Interface defining the structure of the broadcast state
 */
interface BroadcastState {
    /** General broadcast data that can be of any type */
    generalData: any | null;
    /** Buffer for Gantt activity changes */
    ganttActivityBufferData: {
        toAdd: any[];     // Activities to be added
        toUpdate: any[];  // Activities to be updated
        toDelete: any[];  // Activities to be deleted
    };
    /** Buffer for Gantt link changes */
    ganttLinkBufferData: {
        toAdd: any[];     // Links to be added
        toUpdate: any[];  // Links to be updated
        toDelete: any[];  // Links to be deleted
    };
    /** WebSocket connection status */
    isConnected: boolean;
}

const initialState: BroadcastState = {
    generalData: null,
    ganttActivityBufferData: {
        toAdd: [],
        toUpdate: [],
        toDelete: [],
    },
    ganttLinkBufferData: {
        toAdd: [],
        toUpdate: [],
        toDelete: [],
    },
    isConnected: false,
};

/**
 * Redux slice for managing broadcast state
 */
const broadcastSlice = createSlice({
    name: 'broadcast',
    initialState,
    reducers: {
        /** Updates the general broadcast data */
        setGeneralBroadcastData: (state, action: PayloadAction<any>) => {
            state.generalData = action.payload;
        },
        /** Updates the WebSocket connection status */
        setConnectionStatus: (state, action: PayloadAction<boolean>) => {
            state.isConnected = action.payload;
        },
        addGanttBufferDataToAdd: (state, action: PayloadAction<any>) => {
            state.ganttActivityBufferData.toAdd.push(action.payload);
        },
        addGanttBufferDataToUpdate: (state, action: PayloadAction<any>) => {
            state.ganttActivityBufferData.toUpdate.push(action.payload);
        },
        addGanttBufferDataToDelete: (state, action: PayloadAction<any>) => {
            state.ganttActivityBufferData.toDelete.push(action.payload);
        },
        addGanttLinkBufferDataToAdd: (state, action: PayloadAction<any>) => {
            state.ganttLinkBufferData.toAdd.push(...action.payload);
        },
        addGanttLinkBufferDataToUpdate: (state, action: PayloadAction<any>) => {
            state.ganttLinkBufferData.toUpdate.push(...action.payload);
        },
        addGanttLinkBufferDataToDelete: (state, action: PayloadAction<any>) => {
            state.ganttLinkBufferData.toDelete.push(action.payload);
        },
        clearGanttActivityBufferData: (state) => {
            state.ganttActivityBufferData = {
                toAdd: [],
                toUpdate: [],
                toDelete: [],
            };
        },
        clearGanttLinkBufferData: (state) => {
            state.ganttLinkBufferData = {
                toAdd: [],
                toUpdate: [],
                toDelete: [],
            };
        },
    },
});

export const {
    setGeneralBroadcastData,
    setConnectionStatus,
    addGanttBufferDataToAdd,
    addGanttBufferDataToUpdate,
    addGanttBufferDataToDelete,
    addGanttLinkBufferDataToAdd,
    addGanttLinkBufferDataToUpdate,
    addGanttLinkBufferDataToDelete,
    clearGanttActivityBufferData,
    clearGanttLinkBufferData,
} = broadcastSlice.actions;

/**
 * Selectors for accessing broadcast state
 */
/** Returns the general broadcast data */
export const selectGeneralBroadcastData = (state: RootState) => state.broadcast.generalData;
/** Returns the WebSocket connection status */
export const selectBroadcastConnection = (state: RootState) => state.broadcast.isConnected;
/** Returns the Gantt activity buffer data */
export const selectGanttActivityBufferData = (state: RootState) => state.broadcast.ganttActivityBufferData;
/** Returns the Gantt link buffer data */
export const selectGanttLinkBufferData = (state: RootState) => state.broadcast.ganttLinkBufferData;
/** 
 * Checks if there is any pending data in either activity or link buffers
 * @returns {boolean} True if any buffer contains data
 */
export const hasActivityBufferData = (state: RootState) =>
    state.broadcast.ganttActivityBufferData.toAdd.length +
        state.broadcast.ganttActivityBufferData.toUpdate.length +
        state.broadcast.ganttActivityBufferData.toDelete.length +
        state.broadcast.ganttLinkBufferData.toAdd.length +
        state.broadcast.ganttLinkBufferData.toUpdate.length +
        state.broadcast.ganttLinkBufferData.toDelete.length >
    0;

export default broadcastSlice.reducer;
