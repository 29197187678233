import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import Link from '../../models/link.model';

const linksAdapter = createEntityAdapter({
    selectId: (link: Link) => link.id,
});

export interface LinkSliceState {
    linksDictionary: EntityState<Link, number>;
    linksStyles: string;
    linkBroadcastData: any;
}

const initialState: LinkSliceState = {
    linksDictionary: linksAdapter.getInitialState(),
    linksStyles: '',
    linkBroadcastData: {},
};

const linkSlice = createSlice({
    name: 'link',
    initialState,
    reducers: {
        setLinks: (state, action) => {
            linksAdapter.setAll(state.linksDictionary, action.payload);
        },
        addLink: (state, action) => {
            linksAdapter.addOne(state.linksDictionary, action.payload);
        },
        updateLink: (state, action: PayloadAction<Link>) => {
            linksAdapter.upsertOne(state.linksDictionary, action.payload);
        },
        removeLink: (state, action) => {
            linksAdapter.removeOne(state.linksDictionary, action.payload);
        },
        removeAllLinks: (state) => {
            linksAdapter.removeAll(state.linksDictionary);
        },
        setLinkBroadcastData: (state, action: PayloadAction<any>) => {
            state.linkBroadcastData = action.payload;
        },
        setLinksStyles: (state, action: PayloadAction<string>) => {
            state.linksStyles = action.payload;
        },
        addLinksStyles: (state, action: PayloadAction<string>) => {
            state.linksStyles += action.payload;
        },
    },
});

export const {
    setLinks,
    addLink,
    updateLink,
    removeLink,
    removeAllLinks,
    setLinkBroadcastData,
    setLinksStyles,
    addLinksStyles,
} = linkSlice.actions;

// Selectors
export const {
    selectAll: selectAllLinks,
    selectById: selectLinkById,
    selectIds: selectLinkIds,
    selectEntities: selectLinkEntities,
} = linksAdapter.getSelectors((state: RootState) => state.link.linksDictionary);

export const selectLinkBroadcastData = (state: RootState) => state.link.linkBroadcastData;
export const selectLinksStyles = (state: RootState) => state.link.linksStyles;
export default linkSlice.reducer; 