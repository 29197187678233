import activityService from '../../services/activity.service';
import { setNotificationError } from '../slices/app/error.slice';
import { selectAllLinks, setLinks, setLinksStyles } from '../slices/app/link.slice';
import { selectPlanningSelected } from '../slices/app/planning.slice';
import createAppAsyncThunk from './create-typed-async-thunk';
import buildNotificationErrorObject from '../utils/ErrorSliceUtils';
import linkStyle from '../../helpers/link-style';

export const fetchLinks = createAppAsyncThunk('link/fetchLinks', async (_, { getState, dispatch }) => {
    const state = getState();
    const planningSelected = selectPlanningSelected(state);

    if (!planningSelected) {
        return [];
    }

    try {
        const links = await activityService.listLinksByPlanning(planningSelected.rootActivityId);
        dispatch(setLinks(links));
        // add dotted links styles
        const linksStyles =  linkStyle.buildStyles(links)         // dashed links
        dispatch(setLinksStyles(linksStyles));
        return links;
    } catch (error) {
        dispatch(setNotificationError(buildNotificationErrorObject('Gantt', 'Erreur lors de la récupération des liens', { duration: 0 })));
        console.error('Error fetching links:', error);
        throw error;
    }
});

export const rebuildLinksStyles = createAppAsyncThunk('link/rebuildLinksStyles', async (_, { dispatch, getState }) => {
        const allLinks = selectAllLinks(getState());
        const linksStyles =  linkStyle.buildStyles(allLinks)          // dashed links
        dispatch(setLinksStyles(linksStyles));
        // dispatch(addLinksStyles(linkStyle.buildStyles([linkStyle.formatForState(linkData)])));
    // }
});


export default fetchLinks;
