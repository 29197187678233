import { Middleware } from '@reduxjs/toolkit';
import { 
  clearGanttActivityBufferData,
  clearGanttLinkBufferData,
  hasActivityBufferData,
  selectGanttActivityBufferData,
  selectGanttLinkBufferData,
} from '../slices/app/broadcast.slice';
import { batchUpdateGanttLinks, batchUpdateGanttTasks, focusInlineEditor } from '../../helpers/planning';
import { rebuildLinksStyles } from '../thunks/link-slice.thunk';
import { updateTaskStripes } from '../../helpers/planningUtils';



// Use the type in the middleware declaration
const MAX_INTERVAL = 5000; // 5 seconds
const MIN_INTERVAL = 300;  // 300 milliseconds
const BACKOFF_FACTOR = 1.5;
const SPEEDUP_FACTOR = 0.5;

export const ganttBatchUpdateMiddleware: Middleware = store => {
  let timeoutId: any = null;
  let currentInterval = MIN_INTERVAL;

  const adjustInterval = (hasData: boolean) => {
    if (hasData) {
      currentInterval = Math.min(MAX_INTERVAL, currentInterval * BACKOFF_FACTOR);
    } else {
      currentInterval = Math.max(MIN_INTERVAL, currentInterval * SPEEDUP_FACTOR);
    }
    console.log('currentInterval', currentInterval);
  };

  const scheduleBatchUpdate = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    /* eslint-disable-next-line */
    timeoutId = setTimeout(batchUpdate, currentInterval);
  };

  const batchUpdate = () => {
    const {toAdd, toUpdate, toDelete} = selectGanttActivityBufferData(store.getState());
    const {toAdd: linkToAdd, toUpdate: linkToUpdate, toDelete: linkToDelete} = selectGanttLinkBufferData(store.getState());
    const hasBufferData = hasActivityBufferData(store.getState());
    adjustInterval(hasBufferData);

    if (hasBufferData) {
      try {
        console.log('doing batch update', toAdd.length + toUpdate.length + toDelete.length + linkToAdd.length + linkToUpdate.length + linkToDelete.length);
        batchUpdateGanttTasks({toAdd, toUpdate, toDelete});
        batchUpdateGanttLinks({toAdd: linkToAdd, toUpdate: linkToUpdate, toDelete: linkToDelete});
        focusInlineEditor();
        updateTaskStripes(store.dispatch);
        (store.dispatch as any)(rebuildLinksStyles());
        store.dispatch(clearGanttActivityBufferData());
        store.dispatch(clearGanttLinkBufferData());
      } catch (error) {
        console.error('Error during batch update:', error);
      }
    }

    // Schedule the next update
    scheduleBatchUpdate();
  };

  return next => action => {
    const result = next(action);

    // Start timeout if not already running
    if (!timeoutId) {
      scheduleBatchUpdate();
    }

    return result;
  };
}; 

export default ganttBatchUpdateMiddleware;