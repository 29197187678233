import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface UserBroadcastState {
  updatedTeam: any | null;
  updatedUserId: number;
  updatingData: boolean;
}

const initialState: UserBroadcastState = {
  updatedTeam: null,
  updatedUserId: 0,
  updatingData: false
};

const userBroadcastSlice = createSlice({
  name: 'userBroadcast',
  initialState,
  reducers: {
    setUserBroadcastData: (state, action: PayloadAction<any>) => {
      const { updatedTeam, updatedUserId, updatingData } = action.payload;
      if (updatedTeam) {
        state.updatedTeam = updatedTeam;
      }
      if (updatedUserId) {
        state.updatedUserId = updatedUserId;
      }
      if (updatingData) {
        state.updatingData = updatingData;
      }
    }
  }
});

export const {
  setUserBroadcastData
} = userBroadcastSlice.actions;

// Selectors
export const selectUpdatedTeam = (state: RootState) => state.userBroadcast.updatedTeam;
export const selectUpdatedUserId = (state: RootState) => state.userBroadcast.updatedUserId;
export const selectUpdatingData = (state: RootState) => state.userBroadcast.updatingData;

export default userBroadcastSlice.reducer; 