import { detailedDiff } from 'deep-object-diff';
import { EventSourceMessage } from '@microsoft/fetch-event-source';
import { clone } from 'lodash';
import { createAppAsyncThunk } from './create-typed-async-thunk';
import activityService from '../../services/activity.service';
import {
    selectActivityEntities,
    selectBaselineActivityEntities,
    removeActivity,
    // setActivityBroadcastData,
} from '../slices/app/activity.slice';
import { refreshPlanningCustomFields } from './planning-slice.thunk';
import { selectCurrentUser } from '../slices/app/user.slice';
import customFieldService from '../../services/custom-field.service';
import { selectTabPreferences } from '../slices/app/tab.slice';
import { setGeneralBroadcastData } from '../slices/app/broadcast.slice';
import handleFolderUpdate from './folder-slice.thunk';
import { handleUserBroadcastMessage } from './user-broadcast-slice.thunk';
import { removeLink, updateLink } from '../slices/app/link.slice';
import handleGanttActivityDataBuffer, { handleGanttLinkDataBuffer } from './gantt-data-buffer.thunk';

/**
 * Interface defining the structure of detailed differences between objects
 * Used to track changes in activity data
 */
interface DetailedDiff {
    added: Record<string, any>;    // Properties that were added
    updated: Record<string, any>;  // Properties that were modified
    deleted: Record<string, any>;  // Properties that were removed
}

/**
 * Handles broadcast messages for Activities and Gantt Links
 * @param event - EventSourceMessage containing the broadcast data
 * 
 * Key functionality:
 * - Processes real-time updates for Activities and GanttLinks
 * - Updates activity data with custom colors based on planning preferences
 * - Calculates differences between old and new activity states
 * - Handles activity deletion if fetch fails
 * - Manages Gantt link creation and removal
 */
const handleActivityBroadcastMessage = createAppAsyncThunk(
    'broadcast/handleActivityMessage',
    async (event: EventSourceMessage, { dispatch, getState }) => {
        if ((window as any).disableBroadcastUpdate || !(window as any).ganttInstance || event.data === '') return;

        try {
            const data = JSON.parse(event.data);
            console.log('XXXXX______________data', data);
            if (data.type === 'Activity') {
                const state = getState();
                const activitiesDictionary = selectActivityEntities(state);
                const baselineDictionary = selectBaselineActivityEntities(state);

                let updatedActivity;
                try {
                    const tabPreferences = selectTabPreferences(state);
                    const planningColor = tabPreferences?.planning_color;
                    if (data.origin === 'FRONT') {
                        if (planningColor) {
                            updatedActivity = await activityService.showActivityWithColors(data.id, planningColor.id);
                        } else {
                            updatedActivity = await activityService.showActivity(data.id);
                        }
                    } else {
                        updatedActivity = data.data;
                        // custom activity color management
                        if (planningColor) {
                            data.data.colors =
                                data.data.unifiedfields.find((item) => item.id === planningColor.id)?.colors || [];
                        } else {
                            data.data.colors = [];
                        }
                    }
                } catch (error) {
                    console.error('Failed to fetch updated activity:', error);
                    const taskToDelete = (window as any).ganttInstance?.getTaskByServerId(+data.id);
                    if (taskToDelete) {
                        (window as any).ganttInstance?.deleteTask(taskToDelete.id);
                        dispatch(removeActivity(taskToDelete.serverId));
                    }
                    return;
                }

                if (!updatedActivity) return;

                const isTaskExist = (window as any).ganttInstance?.getTaskByServerId(+data.id) !== null;
                const oldActivityInfo: any = clone(activitiesDictionary[data.id]);

                delete oldActivityInfo?.taskDuration;

                const updatedActivityData = {
                    ...updatedActivity,
                    baseLineStartDate: baselineDictionary[updatedActivity.identity]?.startDate || null,
                    baseLineEndDate: baselineDictionary[updatedActivity.identity]?.endDate || null,
                    baseLineProgress: baselineDictionary[updatedActivity.identity]?.avancement || 0,
                };

                const diffFields = !oldActivityInfo
                    ? Object.keys(updatedActivityData)
                    : [
                          ...new Set([
                              ...Object.keys((detailedDiff(oldActivityInfo, updatedActivityData) as DetailedDiff).added),
                              ...Object.keys((detailedDiff(oldActivityInfo, updatedActivityData) as DetailedDiff).updated),
                              ...Object.keys((detailedDiff(oldActivityInfo, updatedActivityData) as DetailedDiff).deleted),
                          ]),
                      ];

                if (diffFields.length > 0) {
                    const broadcastData = {
                        id: +data.id,
                        isTaskExist,
                        context: data.context,
                        isNewTask: !oldActivityInfo,
                        updatedData: updatedActivityData,
                        diffFields,
                    };  
                    // dispatch(setActivityBroadcastData(broadcastData));
                    dispatch(handleGanttActivityDataBuffer(broadcastData));
                }
            }
            if (data.type === 'GanttLink') {
                const linkBroadcastData: any = {};
                linkBroadcastData.isLinkExists = (window as any).ganttInstance.getLinkByServerId(+data.id) !== null;
                linkBroadcastData.updatedData = data.data;
                linkBroadcastData.id = +data.id;

                if (data.data) {
                    await dispatch(updateLink(data.data));
                } else {
                    await dispatch(removeLink(data.id));
                }
                dispatch(handleGanttLinkDataBuffer(linkBroadcastData));
            }
        } catch (error) {
            console.error('Broadcast message handling error:', error);
            throw error;
        }
    }
);

/**
 * Handles general broadcast messages for system-wide updates
 * @param event - EventSourceMessage containing the broadcast data
 * 
 * Processes updates for:
 * - Folders and Planning data
 * - User, Team, and Preference changes
 * - UnifiedField updates (custom fields)
 * 
 * Special handling:
 * - For UnifiedField updates, only processes if not triggered by current user
 * - Supports both update and delete operations for custom fields
 * - Stores broadcast data in general broadcast state
 */
export const handleGeneralBroadcastMessage = createAppAsyncThunk(
    'broadcast/handleGeneralMessage',
    async (event: EventSourceMessage, { dispatch, getState }) => {
        if (event.data === '') return;

        try {
            const data = JSON.parse(event.data);

            // Store the broadcast data
            dispatch(setGeneralBroadcastData(data));

            // Handle different types of broadcasts
            switch (data.type) {
                case 'Folder':
                case 'Planning':
                    await dispatch(handleFolderUpdate(event));
                    break;

                case 'User':
                case 'Team':
                case 'Preference':
                    await dispatch(handleUserBroadcastMessage(event));
                    break;

                case 'UnifiedField': {
                    const currentUser = selectCurrentUser(getState());
                    if (currentUser.userId !== data.user) {
                        let customField;
                        try {
                            customField = await customFieldService.getCustomFieldById(data.id);
                        } catch (error) {
                            customField = null;
                        }
                        if (customField) {
                            dispatch(refreshPlanningCustomFields('broadcast', customField));
                        } else {
                            dispatch(refreshPlanningCustomFields('delete', { id: Number(data.id) }));
                        }
                    }
                    break;
                }
                default:
                    console.log('Unknown general broadcast type:', data.type);
            }
        } catch (error) {
            console.error('Error handling general broadcast message:', error);
        }
    }
);

export default handleActivityBroadcastMessage;
